

//= text_streach 
@mixin text_streach ($max, $min, $color, $font:'Mont', $w:400) {
    font-family: $font;
    font-size: $max + px;
    font-weight: $w;
    color: $color;
    line-height: 120%;

    @media (max-width: 1910px){
        font-size: calc(#{$min}px + (#{$max - $min}) * (100vw - 1200px) / 710);
    }
    @media (max-width: 1200px){
        font-size: $min + px;
    }
}
