@import '../../scss/variables.scss';
@import '../../scss/mixin.scss';

.footer {
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    margin: 30px 0px 20px 0px;

    // .footer__icon
    &__icon {
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        gap: 30px;
        margin: 0px 0px 10px 0px;
        @media (max-width: 760px){ 
            gap: 20px;
        }
    }

    // .footer__img
    &__img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    // .footer__icon-link
    &__link {
        width: 35px;
        height: 35px;

        // @media (max-width: 760px){ 
        //     width: 30px;
        //     height: 30px;
        // }
    }

    &__text {
        @include text_streach (15, 16, $color-text);
        text-align: center;
        // @media (max-width: 760px){ 
        //     display: none;
        // }
    }
}